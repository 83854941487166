import React from "react"
import ChangeAddress from "../components/changeAddress/changeAddress";
import Layout from "../components/layout/layout"

export default function ChangeOfAddress() {
  return (
    <Layout>
      <ChangeAddress />
    </Layout>
  );
}

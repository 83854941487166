import React from 'react'
import PaylessFormBase from '../paylessFormBase/paylessFormBase'
import { useSiteConfig } from '../layout/getSiteConfig'

export default function ChangeAddress() {
  const { changeAddressInfoApi } = useSiteConfig()

  return (
    <PaylessFormBase {...{
      formName: 'Change of Address' ,
      formFile: '/change-of-address.pdf',
      formPath: 'change-address',
      infoApi: changeAddressInfoApi,
      description: 'To update your address, please use this form with a copy of your Driver’s License.'
    }} />
  )
}
